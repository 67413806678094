<template>
  <div class="card" >
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <b-form-input v-model="card_id" class="btn-square border" @keyup.enter="searchForCard" placeholder="Search/Scan Student ID" required autofocus />
        </div>
        <div class="col-12 py-4" v-if="studentCard" style="font-weight: bold">

          <table class="table">
            <tr>
              <td class="border-top-0">NIC</td>
              <td class="text-lg-right border-top-0">{{studentCard.student.nic}}</td>
            </tr>
            <tr>
              <td>Username</td>
              <td class="text-lg-right">{{studentCard.student.username}}</td>
            </tr>
            <tr>
              <td>First Name</td>
              <td class="text-lg-right">{{studentCard.student.first_name}}</td>
            </tr>
            <tr>
              <td>Last Name</td>
              <td class="text-lg-right">{{studentCard.student.last_name}}</td>
            </tr>
            <tr>
              <td>Student Status</td>
              <td class="text-lg-right" v-if="studentCard.student.status===0">Active</td>
              <td class="text-lg-right" v-else-if="studentCard.student.status===2">Suspended</td>
              <td class="text-lg-right" v-else>Pending</td>
            </tr>
            <tr>
              <td>Card Version</td>
              <td class="text-lg-right">{{studentCard.student.card_version}}</td>
            </tr>
            <tr>
              <td>Card Status</td>
              <td class="text-lg-right">{{studentCard.student.card_active ? "Active" : "Inactive"}}</td>
            </tr>
          </table>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CRUD from '@/services/crud'
export default {
  name: "SearchForm",
  created() {
  },
  data() {
    return {
      card_id: null,
      studentCard: null,
    }
  },
  methods: {
    async searchForCard(){
      this.studentCard = null
      await CRUD.update('/api/backend/student-cards', this.card_id)
          .then(response => {
            if (response.data.success) {
              this.studentCard = response.data.data
              // this.$toasted.show(response.data.message, { type: response.data.type })

              let color = response.data.color || '#09B66D';
              if("Expired Card"===response.data.message) {
                color = '#FF8A48';
              }
              // if("Expired card"===response.data.message) {
              //   color = '';
              // }
              this.$swal({
                html: `<h2 class="text-white display-4">`+response.data.title.toUpperCase()+`</h2><br><h1 class="text-white display-1">` + response.data.message + `</h1>`,
                showConfirmButton: false,
                showCancelButton: false,
                allowEnterKey: true,
                allowEscapeKey: true,
                type: 'success',
                background: color,// response.data.color,
                grow: 'fullscreen',
                timer: 10000,
              });

            } else {
              // this.$toasted.error(response.data.message)
              this.$swal({
                html: `<h2 class="text-white display-4">`+response.data.message+`</h2>`,
                showConfirmButton: false,
                showCancelButton: false,
                allowEnterKey: true,
                allowEscapeKey: true,
                type: 'warning',
                background: '#FF8A48',
                grow: 'fullscreen',
                timer: 10000
              });
            }
          }).catch(error => {
            console.log('error', error)
            // this.$toasted.error("System Error!")
            this.$swal({
              html: `<h2 class="text-white display-4">System Error!</h2>`,
              showConfirmButton: false,
              showCancelButton: false,
              allowEnterKey: true,
              allowEscapeKey: true,
              type: 'error',
              background: '#FF8A48',
              grow: 'fullscreen',
              timer: 10000
            });
          }).finally(() => {
            this.card_id = null
            setTimeout(function () {
              this.studentCard = null
            }, 2000)
          })
    }
  }
}
</script>

<style scoped>

</style>
